import React, { useState, useEffect, useCallback } from "react";
import styles from "./ModelSentiment.module.css";
import Circle from "./Circle.js";

function ModelSentiment({ modelState }) {
  const [projectIds, setProjectIds] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [sentimentData, setSentimentData] = useState({});
  const toggleDropdown = useCallback(() => setIsOpen((prev) => !prev), []);

  const selectProjectId = useCallback((projectId) => {
    setSelectedProjectId(projectId);
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (modelState) {
      const extractProjectIds = (modelState) => {
        const allModels = Object.keys(modelState);
        const allProjectIds = [
          ...new Set(
            allModels.flatMap((model) => Object.keys(modelState[model]))
          ),
        ];
        return Array.from(allProjectIds);
      };

      const uniqueProjectIds = extractProjectIds(modelState);
      setProjectIds(uniqueProjectIds);
      if (uniqueProjectIds.length > 0) {
        setSelectedProjectId(uniqueProjectIds[0]);
      } else {
        setSelectedProjectId(null);
      }
    } else {
      setProjectIds([]);
      setSelectedProjectId(null);
      setSentimentData({});
      setIsLoading(false);
    }
  }, [modelState]);

  useEffect(() => {
    if (
      modelState &&
      (selectedProjectId === "BTC" || selectedProjectId === "ETH")
    ) {
      const fetchData = async () => {
        // setIsLoading(true);
        try {
          const result = modelState;

          const data = {};
          Object.entries(result).forEach(([model, projectData]) => {
            Object.entries(projectData).forEach(
              ([projectId, { z_score, error }]) => {
                if (!error) {
                  if (!data[projectId]) {
                    data[projectId] = {
                      bullish: 0,
                      neutral: 0,
                      bearish: 0,
                      models: {},
                    };
                  }
                  if (z_score > 1.5) data[projectId].bullish++;
                  else if (z_score < -1.5) data[projectId].bearish++;
                  else data[projectId].neutral++;
                  data[projectId].models[model] = {
                    z_score,
                    sentiment: getSentiment(z_score),
                  };
                }
              }
            );
          });

          setSentimentData(data);
        } catch (error) {
          console.error("Error fetching data:", error);
          setSentimentData({});
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else {
      setIsLoading(false);
      setSentimentData({});
    }
  }, [modelState, selectedProjectId]);

  const getSentiment = (zScore) => {
    if (zScore > 1.5) return "Bullish";
    if (zScore < -1.5) return "Bearish";
    return "Neutral";
  };

  const isBlurred = !(
    selectedProjectId === "BTC" || selectedProjectId === "ETH"
  );

  const handleClick = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink("https://dashboard.yoracle.ai/");
    } else {
      window.open(
        "https://dashboard.yoracle.ai/",
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.circleBG}>
        <img src="/images/circleBG.png" alt="" />
      </div>{" "}
      <div className={styles.subContainer}>
        <div className={styles.overviewHeader}>
          <div className={styles.headerContainer}>
            <div className={styles.headerText}>Models By Sentiment</div>
            {/* <Tooltip id="models-by-sentiment" page="model-page" /> */}
          </div>

          <div className={styles.dropdownContainer}>
            <div className={styles.dropdown} onClick={toggleDropdown}>
              <span className={styles.selectedOption}>
                {/* <img src={`/images/${selectedProjectId?.toLowerCase()}.svg`} alt={selectedProjectId} /> */}
                {selectedProjectId}
              </span>
              <span className={styles.arrow}>
                {isOpen ? (
                  <img
                    className={styles.invertedArrow}
                    src="/images/arrowDown.svg"
                    alt="arrow down"
                  />
                ) : (
                  <img src="/images/arrowDown.svg" alt="arrow down" />
                )}
              </span>
            </div>
            {isOpen && (
              <div className={styles.dropdownMenu}>
                {projectIds.map((projectId) => (
                  <div
                    key={projectId}
                    className={styles.dropdownItem}
                    onClick={() => selectProjectId(projectId)}
                  >
                    {/* <img src={`/images/${projectId.toLowerCase()}.svg`} alt={projectId} /> */}
                    {projectId}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={styles.circleContainerWrapper}>
          <div
            className={`${styles.circleContainer} ${
              isBlurred ? styles.blurredCard : ""
            }`}
          >
            <Circle
              sentimentCounts={
                sentimentData[selectedProjectId] || {
                  bullish: 0,
                  neutral: 0,
                  bearish: 0,
                }
              }
              projectData={sentimentData[selectedProjectId]?.models || {}}
            />
          </div>

          {isBlurred && selectedProjectId && (
            <div className={styles.btnWrapper}>
              <button onClick={handleClick} className={styles.button}>
                View on Dashboard
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModelSentiment;
