import React from "react";
import styles from "./SliderHeader.module.css";


const SliderHeader = () => {

  const handleDocsClick = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink("https://dashboard.yoracle.ai/docs");
    } else {
      window.open("https://dashboard.yoracle.ai/docs", "_blank", "noopener,noreferrer");
    }
  };
  return (
    <div className={styles.headerContainer}>
      <div className={styles.logoWrapper}>
        <img src="/images/yaiLogo.svg" />{" "}
      </div>
      <div className={styles.btnWrapper}>
        <button onClick={handleDocsClick} className={styles.docsBtn}>
          Docs
        </button>
      </div>
    </div>
  );
};

export default SliderHeader;
