import React, { useEffect, useState } from "react";
import styles from "./ads.module.css";
import { BASE_URL } from "../../constants/constants";
const Ads = () => {
  const [adData, setAdData] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchAdData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/ad?option=tgapp`);
    
        const data = await response.json();
        if (data.img) {
          const imageData = atob(data.img);
          const arrayBuffer = new ArrayBuffer(imageData.length);
          const uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < imageData.length; i++) {
            uint8Array[i] = imageData.charCodeAt(i);
          }
          const blob = new Blob([uint8Array], { type: "image/jpeg" });
          const imageUrl = URL.createObjectURL(blob);
          data.img = imageUrl;
        }
        setAdData(data);
      } catch (err) {
        setError(err.message);
      }
    };
    fetchAdData();
  }, []);
  if (error) {
    return <div className={`${styles.adsWrapper} ${styles.error}`}></div>;
  }
  return (
    <div
      className={styles.gradient}
      onClick={() => (window.location.href = adData?.url)}
    >
      <div className={styles.adsWrapper}>
        <div className={styles.adImage}>
          <img src={adData?.img} className={styles.adImage} />
        </div>
        <div className={styles.textDiv}>{adData?.text}</div>
      </div>
    </div>
  );
};

export default Ads;
