import React, { useState, useEffect } from "react";
import CustomGauge from "./Gauge.js";
import styles from "./Technicals.module.css";

function Technicals({ modelState }) {
  const [projectIds, setProjectIds] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [residuals, setResiduals] = useState({});

  useEffect(() => {
    if (modelState) {
      const extractProjectIds = (modelState) => {
        const allModels = Object.keys(modelState);
        const allProjectIds = [
          ...new Set(
            allModels.flatMap((model) => Object.keys(modelState[model]))
          ),
        ];
        return Array.from(allProjectIds);
      };

      const uniqueProjectIds = extractProjectIds(modelState);
      setProjectIds(uniqueProjectIds);
      if (uniqueProjectIds.length > 0) {
        setSelectedProjectId(uniqueProjectIds[0]);
      } else {
        setSelectedProjectId(null);
      }
      // setProjectIds(modelState.oracle);
      // setSelectedProjectId(modelState.oracle[0]);
    }
  }, [modelState]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedProjectId === "BTC" || selectedProjectId === "ETH") {
          const oracleData = modelState.oracle;
          const newResiduals = oracleData[selectedProjectId];
          setResiduals(newResiduals);
        } else {
          setResiduals();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (selectedProjectId) {
      fetchData();
    }
  }, [selectedProjectId]);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const selectProjectId = (projectId) => {
    setSelectedProjectId(projectId);
    setIsOpen(false);
  };

  const isBlurred = !(
    selectedProjectId === "BTC" || selectedProjectId === "ETH"
  );

  const handleClick = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink("https://dashboard.yoracle.ai/");
    } else {
      window.open(
        "https://dashboard.yoracle.ai/",
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topContainer}>
        <div className={styles.headingContainer}>
          <div className={styles.heading}>Technicals</div>
          {/* <Tooltip id="technicals" /> */}
        </div>
        <div className={styles.dropdownContainer}>
          <div className={styles.dropdown} onClick={toggleDropdown}>
            <span className={styles.selectedOption}>{selectedProjectId}</span>
            <span className={styles.arrow}>
              {isOpen ? (
                <img
                  className={styles.invertedArrow}
                  src="/images/arrowDown.svg"
                  alt="arrow down"
                />
              ) : (
                <img src="/images/arrowDown.svg" alt="arrow down" />
              )}
            </span>
          </div>
          {isOpen && (
            <div className={styles.dropdownMenu}>
              {projectIds?.map((projectId) => (
                <div
                  key={projectId}
                  className={styles.dropdownItem}
                  onClick={() => selectProjectId(projectId)}
                >
                  {projectId}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles.gaugeWrapperContainer}>
        <div
          className={`${styles.gaugeContainer} ${
            isBlurred ? styles.blurredCard : ""
          }`}
        >
          <img
            className={styles.mask}
            src="/images/technicalsMask.svg"
            alt="mask"
          />
          <CustomGauge
            residual={residuals}
            selectedProjectId={selectedProjectId}
          />
        </div>

        {isBlurred && selectedProjectId && (
          <div className={styles.btnWrapper}>
            <button onClick={handleClick} className={styles.button}>
              View on Dashboard
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
export default Technicals;
