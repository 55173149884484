import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";
import styles from "./StateHeatmap.module.css";

import { CUSTOM_HEATMAP_COLORS } from "../../constants/constants";

function StateHeatmap({ modelState }) {
  const [data, setData] = useState({
    BTC: { z_score: 0, residual: 0 },
    ETH: { z_score: 0, residual: 0 },
    SOL: { z_score: 0, residual: 0 },
    WIF: { z_score: 0, residual: 0 },
    BNB: { z_score: 0, residual: 0 },
  });
  const [heatmapData, setHeatmapData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [models, setModels] = useState([]);
  const [project_ids, setProjectIds] = useState([]);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  

  useEffect(() => {
    const fetchModels = async () => {
      try {
        setData(modelState);
      } catch (error) {
        console.error("Error fetching the models:", error);
      }
    };
    if (modelState) {
      fetchModels();
    }
  }, [modelState]);

  useEffect(() => {
    if (modelState) {
      const allModels = Object.keys(modelState);
      const allProjectIds = [
        ...new Set(
          allModels.flatMap((model) => Object.keys(modelState[model]))
        ),
      ];
      // Sort models by name length (longest to shortest)
      const sortedModels = allModels.sort((a, b) => b.length - a.length);

      setModels(sortedModels);
      setProjectIds(allProjectIds);
    }
  }, [modelState]);

  useEffect(() => {
    // if (modelState) {
    const fetchData = async () => {
      try {
        setData(modelState);
        processData(modelState);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    if (modelState) {
      fetchData();
    }
    // }
  }, [modelState]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      processData(data);
    }
  }, [data, models]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const processData = (data) => {
    const heatmapValues = models.map((model) =>
      project_ids.map((project) => data[model]?.[project]?.z_score ?? 0)
    );
    const residualValues = models.map((model) =>
      project_ids.map((project) => data[model]?.[project]?.residual ?? 0)
    );

    setHeatmapData({
      z: heatmapValues,
      x: project_ids,
      y: models,
      type: "heatmap",
      colorscale: CUSTOM_HEATMAP_COLORS,
      showscale: true,
      colorbar: { title: "Z-Score" },
      text: residualValues.map((row) =>
        row.map((value) => (value ?? 0).toFixed(2) + "%")
      ),
      texttemplate: "%{text}",
      hoverinfo: "text",
      zmin: -5,
      zmax: 5,
    });
  };

  const getPlotDimensions = () => {
    if (dimensions.width < 1415) {
      return { width: "100%", height: "100%" };
      // } else if (dimensions.width < 1540) {
      //   return { width: 500, height: 450 };
      // } else if (dimensions.width < 1650) {
      //   return { width: 540, height: 450 };
    } else {
      return { width: 670, height: 450 };
    }
  };

  const getFontSize = () => {
    if (dimensions.width < 600) {
      return 16;
    } else if (dimensions.width < 1440) {
      return 22;
    } else if (dimensions.width < 1600) {
      return 24;
    } else {
      return 28;
    }
  };
  const fontSize = getFontSize();

  const plotDimensions = getPlotDimensions();
  const showColorbar = dimensions.width > 500;

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.headerText}>Heatmap</div>
      </div>{" "}
      <div className={styles.plotContainer}>
        <Plot
          data={[
            {
              ...heatmapData,
              showscale: showColorbar,
              colorbar: showColorbar ? { title: "Z-Score" } : false,
            },
          ]}
          layout={{
            width: dimensions.width < 900 ? "" : plotDimensions.width,
            height: dimensions.width < 900 ? "" : plotDimensions.height,
            autosize: true,
            xaxis: {
              title: {
                text: "Asset",
              },
              color: "#fff",
              showgrid: false,
              zeroline: false,
            },
            yaxis: {
              title: {
                text: "Model",
              },
              color: "#fff",

              showgrid: false,
              zeroline: false,
            },
            paper_bgcolor: "rgba(44, 44, 44, 0.0)",
            plot_bgcolor: "rgba(44, 44, 44, 0)",
            font: { color: "#fff" },

            margin: {
              t: 80,
              r: showColorbar ? 80 : 20,
              b: 50,
              l: 80,
              pad: 6,
            },
          }}
          config={{
            displayModeBar: false,
            staticPlot: true,
            useResizeHandler: true,
            responsive: true,
          }}
          style={{
            width: dimensions.width < 900 ? "100%" : "auto",
            display: "flex",
            justifyContent: dimensions.width > 900 ? "center" : "unset",
            height: dimensions.width > 700 ? "auto" : "100%",
          }}
        />
      </div>
    </div>
  );
}

export default StateHeatmap;
